.App {
  text-align: center;
  height: 100%;
  width: 100%;
}

.error {
  color: red;
}

.success {
  color: green;
}